<template>
  
  <div class="card mb-5 mb-xl-10">
      <div class="card-body">
    <!-- input search -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="allchat"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'fullName'"
          class="text-nowrap"
        >
          <!-- <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          /> -->
          <span class="text-nowrap">{{ props.row.fullName }}</span>
        </div>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'created_at'">
          <span>
            {{ formatDate(props.row.created_at) }}
          </span>
        </span>

        <!-- Column: Action -->
        <!-- <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item>
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Edit</span>
              </b-dropdown-item>
              <b-dropdown-item>
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span> -->

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import { codeColumnSearch } from './code'
import axios from 'axios';
import { formatDate } from '@core/utils/filter'
import Swal from 'sweetalert2'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      pageLength: 3,
      dir: false,
      codeColumnSearch,
      columns: [
        {
          label: 'Channel',
          field: 'channel',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Channel',
          },
        },
        {
          label: 'Penerima',
          field: 'penerima',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Penerima',
          },
        },
        {
          label: 'Pengirim',
          field: 'pengirim',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Pengirim',
          },
        },
        {
          label: 'Pesan',
          field: 'pesan',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Pesan',
          },
        },
        {
          label: 'Status',
          field: 'status',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Status',
          },
        },
        {
          label: 'Tanggal',
          field: 'created_at',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Tanggal',
          },
        },
        // {
        //   label: 'Action',
        //   field: 'action',
        // },
      ],
      rows: [],
      searchTerm: '',
      url: localStorage.getItem('baseapi'),
      pengguna: JSON.parse(localStorage.getItem('userData')),
      allchat:[]
    }
  },
  methods: {
    loaddata(){
      axios.get(this.url+'chat', {
      headers: {
          "Content-type": "application/json",
          "xth": this.pengguna.token,
          }
      })
      .then(response => {
        this.allchat = response.data.data
      })
      .catch(error => {
        if (error.response.data === 'Token Tidak Valid/Hak akses tidak sesuai') {
          this.$swal({
            title: error.response.data,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok',
            allowOutsideClick: false
            }).then((result) => {
            if (result.isConfirmed) {
              localStorage.removeItem('userData')
              // Redirect to login page
              this.$router.push({ name: 'auth-login' })
            }
          })
        } else {
         this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
      })
    }
  },
  mounted() {
    this.loaddata()
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        "Answered by Expert"      : 'light-primary',
        "Assigned"      : 'light-primary',
        "Resolved" : 'light-success',
        "Returned by Expert"     : 'light-danger',
        "Unmapped"     : 'light-danger',
        "Answered"      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.$http.get('/good-table/basic')
      .then(res => { this.rows = res.data })
  },
  setup(props) {
    return{
      formatDate
    }
  }
}
</script>
